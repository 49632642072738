import type { GetStaticProps } from 'next';
import React from 'react';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import Script from 'next/script';

import { Logo, Wrap, ToastProvider } from '@leafwell/components';
import Blocks from 'components/blocks';
import HeroCard from 'components/hero-card';
import Loader from 'components/loader';
import { getUsLandingPage } from 'lib/queries/us-landings';
import { PageProps } from 'lib/queries/pages';

const UsLandingHome: React.FC<PageProps> = ({
  page: {
    modified,
    acf: { hero, redirectCannabisCounseling, blocks, pageType, states },
  },
  partnersReviews,
}) => {
  const siteTitle = 'Leafwell';

  const { isFallback } = useRouter();
  const cardPageSettings = {
    affiliateWebsite: null,
    affiliateLogo: null,
    formUrl: null,
    vpdPage: null,
    pauseAppointments: false,
    redirectMedicalCard: true,
    totalMedicalCardPrice: null,
    utmParams: {
      utm_campaign: 'mc_embed',
      utm_medium: 'satellite',
      utm_source: 'leafwell',
    },
    name: null,
    description: null,
    imageObjectCaption: null,
    product: {
      name: null,
      description: null,
      imageUrl: null,
    },
    isRenewal: false,
    stateProductImage: null,
    vpdPartnerName: null,
    vpdLogo: null,
    price: {
      price: null,
      newPrice: null,
      percentageDiscount: null,
      discountCode: null,
    },
    customStateCode: null,
  };

  if (isFallback) {
    return <Loader />;
  }

  return (
    <>
      <NextSeo
        key="us-landing-home"
        title={siteTitle}
        nofollow={true}
        noindex={true}
        robotsProps={{ noarchive: true }}
      />
      {process.env.NODE_ENV === 'production' ? (
        <>
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=G-13L6MSSZMY"
          />
          <Script
            async
            src="https://www.googletagmanager.com/gtag/js?id=AW-10889710135"
          />
          <Script
            id="google-tag-init"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-13L6MSSZMY');
                gtag('config', 'AW-10889710135');`,
            }}
          />
          <Script
            id="google-ads-conversion-tracking"
            dangerouslySetInnerHTML={{
              __html: `function gtag_report_conversion() {
                gtag('event', 'conversion', {
                    'send_to': 'AW-10889710135/ODaCCO6PxZcaELeUz8go'
                });
                return false;
              }`,
            }}
          />
          <Script
            id="facebook-ad-script"
            dangerouslySetInnerHTML={{
              __html: `!function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;
                n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', '691442679014764');
                fbq('track', 'PageView');`,
            }}
          />
          <noscript>
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src="https://www.facebook.com/tr?id=691442679014764&ev=PageView&noscript=1"
            />
          </noscript>
        </>
      ) : null}
      <ToastProvider>
        <Wrap className="!py-6">
          <Logo
            {...{
              classNameButton: 'w-32 xl:w-40 z-10 lg:z-20',
              href: '',
              title: siteTitle,
            }}
          />
        </Wrap>
        <HeroCard
          hero={hero}
          states={states}
          showBadges={false}
          showStateSelect={false}
          spacer={false}
          redirectStatePage={true}
          showTrustpilot={true}
          pageType={pageType}
          formUrl={cardPageSettings.formUrl}
          affiliateLogo={cardPageSettings.affiliateLogo}
          affiliateWebsite={cardPageSettings.affiliateWebsite}
          pauseAppointments={cardPageSettings.pauseAppointments}
          redirectMedicalCard={cardPageSettings.redirectMedicalCard}
          redirectCannabisCounseling={redirectCannabisCounseling}
          totalMedicalCardPrice={cardPageSettings.totalMedicalCardPrice}
        />
        {blocks.length > 0 ? (
          <Blocks
            blocks={blocks}
            modified={modified}
            pageType={pageType}
            states={states}
            partnersReviews={partnersReviews}
          />
        ) : null}
      </ToastProvider>
    </>
  );
};

export const getStaticProps: GetStaticProps = async context => {
  return await getUsLandingPage(context);
};

export default UsLandingHome;
